import axios from 'axios';

export default {
  namespaced: true,
  state() {
    return {
      processStatus: null,
      taskStatus: null,
      taskQueue: null,
    };
  },
  getters: {

  },
  mutations: {
    loadProcessStatusMutation(state, activeItems) {
      state.processStatus = activeItems;
    },
    loadTaskStatusMutation(state, taskStatus) {
      state.taskStatus = taskStatus;
    },
    loadTaskQueueMutation(state, taskQueue) {
      state.taskQueue = taskQueue;
    },
  },
  actions: {
    loadProcessStatus({ commit }, authToken) {
      return axios.get('/api/process-status', {
        headers: {
          'WfDemo-Auth': authToken
        }
       }).then((result) => {
        commit('loadProcessStatusMutation', result.data);
        return result.data;
       });
    },
    loadTaskStatus({ commit }, authToken) {
      return axios.get('/api/task-status', {
        headers: {
          'WfDemo-Auth': authToken
        }
       }).then((result) => {
        commit('loadTaskStatusMutation', result.data);
        return result.data;
       });
    },
    loadTaskQueue({ commit }, authToken) {
      return axios.get('/api/task-queue', {
        headers: {
          'WfDemo-Auth': authToken
        }
       }).then((result) => {
        commit('loadTaskQueueMutation', result.data);
        return result.data;
       });
    },
    updateItem({ commit }, params) {
      return axios.put('/api/item/' + params.updatedItem.id, params.updatedItem, {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    salesUpdateItem({ commit }, params) {
      return axios.put('/api/sales-items/' + params.item.id, params.item, {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    salesCreateItem({ commit }, params) {
      return axios.post('/api/sales-items', params.item, {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    sendToAssembly({ commit }, params) {
      return axios.put('/api/item/to-assembly/' + params.itemId, [], {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    sendToInspection({ commit }, params) {
      return axios.put('/api/item/to-inspection/' + params.itemId, [], {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    sendToShipping({ commit }, params) {
      return axios.put('/api/item/to-shipping/' + params.itemId, [], {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    sendToComplete({ commit }, params) {
      return axios.put('/api/item/to-complete/' + params.itemId, [], {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    returnToInspection({ commit }, params) {
      return axios.put('/api/item/return-inspection/' + params.itemId, {reason: params.reason}, {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    returnToAssembly({ commit }, params) {
      return axios.put('/api/item/return-assembly/' + params.itemId, {reason: params.reason}, {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    returnToSales({ commit }, params) {
      return axios.put('/api/item/return-sales/' + params.itemId, {reason: params.reason}, {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    }
  },
};
