<template>
    <div class="row status-table-first-row">
        <div class="col-lg-12">
            <h4 class="float-start">
                <span>Task Queue</span>
            </h4>
            <div class="last-updated-container float-start"><span :class="updateChanged ? 'flashClass' : ''">Updated: {{ lastUpdated }}</span></div>
        </div>
    </div>
    <div class="row" v-if="initialLoading && loading" >
        <div class="col-lg-12 text-center">
            <img src="@/assets/images/loader.gif" />
        </div>
    </div>
    <div v-if="!initialLoading || !loading" >
        <div class="row">
            <div class="col-lg-12">
                <div v-for="(item, index) in taskQueue" :key="item.id" :class="this.isEven(index) ? 'row status-table-row status-table-even-row' : 'row status-table-row status-table-odd-row'">
                    <div class="col-lg-10">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="status-table-column-header">Order ID</div>
                                <div class="status-table-column-content">{{ item.id }}</div>
                            </div>
                            <div class="col-lg-2">
                                <div class="status-table-column-header">Product</div>
                                <div class="status-table-column-content">{{ item.description }}</div>
                            </div>
                            <div class="col-lg-2">
                                <div class="status-table-column-header">Customer</div>
                                <div class="status-table-column-content">{{ item.customer.name }}</div>
                            </div>
                            <div class="col-lg-3">
                                <div class="status-table-column-header">Time In Queue</div>
                                <div class="status-table-column-content">
                                    {{ item.elapsedTime.days }} day{{ item.elapsedTime.days != 1 ? 's' : '' }},
                                    {{ item.elapsedTime.hours }} hour{{ item.elapsedTime.hours != 1 ? 's' : '' }},
                                    {{ item. elapsedTime.minutes }} minute{{ item. elapsedTime.minutes != 1 ? 's' : '' }}
                                </div>
                            </div>
                        </div>
                        <div class="row"  v-if="item.returnedReason">
                            <div class="col-lg-12 returned-reason-message">
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                Order returned to you because: {{ item.returnedReason }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-content"><button type="button" class="btn btn-secondary edit-item-btn show-modal-btn" @click="showItemModal(item.id)">View Details</button></div>
                        <div v-if="user.role === 'assembler'">
                            <div class="status-table-column-content"><button type="button" class="btn btn-warning sendback-item-btn show-modal-btn" @click="showReturnToModal(item.id, 'Sales')">Return To Sales</button></div>
                            <div class="status-table-column-content"><button type="button" class="btn btn-success advance-item-btn show-modal-btn" @click="showSendToModal(item.id, 'Inspection')">Ready For Inspection</button></div>
                        </div>
                        <div v-if="user.role === 'inspector'">
                            <div class="status-table-column-content"><button type="button" class="btn btn-warning sendback-item-btn show-modal-btn" @click="showReturnToModal(item.id, 'Assembly')">Return To Assembly</button></div>
                            <div class="status-table-column-content"><button type="button" class="btn btn-success advance-item-btn show-modal-btn" @click="showSendToModal(item.id, 'Shipping')">Ready For Shipping</button></div>
                        </div>
                        <div v-if="user.role === 'shipper'">
                            <div class="status-table-column-content"><button type="button" class="btn btn-warning sendback-item-btn show-modal-btn" @click="showReturnToModal(item.id, 'Inspection')">Return To Inspector</button></div>
                            <div class="status-table-column-content"><button type="button" class="btn btn-success advance-item-btn show-modal-btn" @click="showSendToModal(item.id, 'Complete')">Order Is Shipped</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="item-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>View Order</span>
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div><b>Customer</b></div>
                    <div class="modal-item-content">
                        {{ selectedItem.customer.name }}
                    </div>
                    <div><b>Product Description</b></div>
                    <div class="modal-item-content">
                        {{ selectedItem.description }}
                    </div>
                    <div><b>Created</b></div>
                    <div class="modal-item-content">
                        {{ new Date(selectedItem.created).toLocaleString() }}
                    </div>
                    <div><b>Updated</b></div>
                    <div class="modal-item-content">
                        {{ new Date(selectedItem.updated).toLocaleString() }}
                    </div>
                    <div v-if="selectedItem.notes.length > 0">
                        <div><b>Notes</b></div>
                        <div class="modal-item-content item-notes-container">
                            <div v-for="note in selectedItem.notes" :key="note.id">{{ new Date(note.timestamp).toLocaleString() }} - {{ note.userRole }} : {{ note.message }}</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary modal-btn" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="send-to-modal">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Confirm Ready For {{ sendToDestination }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure you want to send the order to {{ sendToDestination }}?
                </div>
                <div class="modal-footer">
                    <div v-if="showModalLoader" class="text-center">
                        <img class="modal-loader" src="@/assets/images/loader.gif" />
                    </div>
                    <button type="button" class="btn btn-secondary modal-btn confirm-modal-btn" data-bs-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary modal-btn confirm-modal-btn" @click="doSendTo()">Yes</button>
                </div>
                <div class="alert alert-danger save-error" v-if="sendToError" v-html="sendToError"></div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="return-to-modal">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Confirm Return To {{ returnToDestination }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div><b>Reason</b></div>
                    <div class="modal-item-content">
                        <div>
                            <textarea class="form-control changeable-field" id="note" name="note" v-model="returnToReason" required></textarea>
                            <ValidationMessage :model="v$.returnToReason" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div v-if="showModalLoader" class="text-center">
                        <img class="modal-loader" src="@/assets/images/loader.gif" />
                    </div>
                    <button type="button" class="btn btn-secondary modal-btn confirm-modal-btn" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary modal-btn confirm-modal-btn" @click="doReturnTo()">Submit</button>
                </div>
                <div class="alert alert-danger save-error" v-if="returnToError" v-html="returnToError"></div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ValidationMessage from '@/components/ValidationMessage.vue';

var updateQueue;
var updateIntervalTimer;

export default {
    setup () {
        return {
            v$: useVuelidate()
        }
    },
    props: {
        user: Object
    }, 
    data() {
        return {
            taskQueue: [],
            loading: false,
            initialLoading: true,
            lastUpdated: "",
            updateChanged: false,
            itemModal: null,
            selectedItem: null,

            showModalLoader: false,

            sendToError: "",
            sendToModal: null,
            sendToItemId: null,
            sendToDestination: "",
            
            returnToError: "",
            returnToModal: null,
            returnToItemId: null,
            returnToReason: "",
            returnToDestination: "",

        };
    },
    validations () {
        return {
            returnToReason: { required, $autoDirty: true }
        }
    },
    emits: ["login-expired", "show-success"],
    methods: {
        async loadQueue() {
            this.loading = true;
            this.$store.dispatch('items/loadTaskQueue', VueCookies.get('wfdemo-fe-auth'))
            .then((taskQueue) => {
                console.log(taskQueue);
                this.taskQueue = taskQueue;
                this.loading = false;
                this.lastUpdated = new Date().toLocaleString();
                if(this.initialLoading) {
                    this.initialLoading = false;
                } else {
                    this.updateChanged = true;
                    setTimeout(() => {
                        this.updateChanged = false;
                    }, 3000);
                }
            })
            .catch((result) => {
                this.loading = false;
                if(result.response.status === 401) {
                    this.$emit('login-expired');
                }
            }); 
        },
        showItemModal(itemId) {
            this.showModalLoader = false;
            this.emptySelectedItem();
            this.taskQueue.forEach((item) => {
                if(item.id === itemId) {
                    this.selectedItem = {
                        id: item.id,
                        description: item.description,
                        customer: {
                            id: item.customer.id,
                            name: item.customer.name,
                        },
                        created: item.created,
                        updated: item.updated,
                        notes: item.notes
                    }
                }
            });
            this.itemModal = new bootstrap.Modal(document.getElementById('item-modal'));
            this.itemModal.show();
        },
        emptySelectedItem() {
            this.selectedItem = {
                id: "",
                description: "",
                customer: {
                    id: "",
                    name: "",
                },
                created: "",
                updated: "",
                notes: [],
                newNote: ""
            }
        },
        showSendToModal(itemId, destination) {
            this.showModalLoader = false;
            this.sendToItemId = itemId;
            this.sendToDestination = destination;
            this.sendToError = "";
            this.sendToModal = new bootstrap.Modal(document.getElementById('send-to-modal'));
            this.sendToModal.show();
        },
        async doSendTo() {
            this.sendToError = "";
            this.showModalLoader = true;
            this.$store.dispatch("items/sendTo" + this.sendToDestination, 
                {itemId: this.sendToItemId, authToken: VueCookies.get('wfdemo-fe-auth')})
                    .then(() => {
                        this.showModalLoader = false;
                        this.sendToModal.hide();
                        this.loadQueue();
                        this.$emit('show-success', 'Item sent to ' + this.sendToDestination);
                    })
                    .catch((result) => {
                        this.showModalLoader = false;
                        if(result.response.data.hasOwnProperty('messages')) {
                            if(Array.isArray(result.response.data.messages)) {
                                this.sendToError =  result.response.data.messages.join('<br>');
                            } else {
                                if(result.response.data.messages.hasOwnProperty('error')) {
                                    this.sendToError = result.response.data.messages.error;
                                } else {
                                    this.sendToError = 'An error occurred';
                                }                                    
                            }
                        } else {
                            this.sendToError = 'An error occurred';
                        }     
            });
        },
        showReturnToModal(itemId, destination) {
            this.showModalLoader = false;
            this.returnToItemId = itemId;
            this.returnToDestination = destination;
            this.returnToError = "";
            this.returnToReason = "";
            this.returnToModal = new bootstrap.Modal(document.getElementById('return-to-modal'));
            this.returnToModal.show();
        },
        async doReturnTo() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                return;
            }

            this.returnToError = "";
            this.showModalLoader = true;
            this.$store.dispatch("items/returnTo" + this.returnToDestination, 
                {itemId: this.returnToItemId, reason: this.returnToReason, authToken: VueCookies.get('wfdemo-fe-auth')})
                    .then(() => {
                        this.showModalLoader = false;
                        this.returnToModal.hide();
                        this.loadQueue();
                        this.$emit('show-success', 'Item returned to ' + this.returnToDestination);
                    })
                    .catch((result) => {
                        this.showModalLoader = false;
                        if(result.response.data.hasOwnProperty('messages')) {
                            if(Array.isArray(result.response.data.messages)) {
                                this.returnToError =  result.response.data.messages.join('<br>');
                            } else {
                                if(result.response.data.messages.hasOwnProperty('error')) {
                                    this.returnToError = result.response.data.messages.error;
                                } else {
                                    this.returnToError = 'An error occurred';
                                }                                    
                            }
                        } else {
                            this.returnToError = 'An error occurred';
                        }    
            });
        },
        isEven(index) {
            return index % 2 === 0;
        }
    },
    computed: {
        
    },
    created() {
        updateQueue = this.loadQueue;
        this.loadQueue();
        if(process.env.VUE_APP_DASHBOARD_UPDATE > 0) {
            updateIntervalTimer = window.setInterval(
                function() {
                    updateQueue();
                },
                process.env.VUE_APP_DASHBOARD_UPDATE
            );
        }
        this.emptySelectedItem();
    },
    beforeUnmount() {
        clearInterval(updateIntervalTimer);
    },
    components: {
        ValidationMessage,
    }
}
</script>
