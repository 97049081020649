<template>
    <div class="row status-table-first-row">
        <div class="col-lg-12">
            <h4 class="float-start">Task Status</h4>
            <div class="last-updated-container float-start"><span :class="updateChanged ? 'flashClass' : ''">Updated: {{ lastUpdated }}</span></div>
        </div>
    </div>
    <div class="row" v-if="initialLoading && loading" >
        <div class="col-lg-12 text-center">
            <img src="@/assets/images/loader.gif" />
        </div>
    </div>
    <div v-if="!initialLoading || !loading" >
        <div class="row">
            <div class="col-lg-3">
                <div class="text-center">Relative hours at status</div>
                <Pie
                    id="by-status-chart-id"
                    :options="byStatusChartOptions"
                    :data="byStatusChartData"
                />
            </div>
            <div class="col-lg-9">
                <div v-for="(item, index) in taskStatus" :key="item.status" :class="this.isEven(index) ? 'row status-table-row status-table-even-row' : 'row status-table-row status-table-odd-row'">
                    <div class="col-lg-1">
                        <div class="status-table-column-header">Status</div>
                        <div class="status-table-column-content">{{ item.status }}</div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-header">Process Count</div>
                        <div class="status-table-column-content">{{ item.count }}</div>
                    </div>
                    <div class="col-lg-4">
                        <div class="status-table-column-header">Average Time At Status</div>
                        <div class="status-table-column-content">
                            <div v-if="item.count > 0">
                                {{ item.aveElapsedTime.days }} day{{ item.aveElapsedTime.days !== 1 ? "s" : "" }}
                                {{ item.aveElapsedTime.hours }} hour{{ item.aveElapsedTime.hours !== 1 ? "s" : "" }}
                                {{ item.aveElapsedTime.minutes }} minute{{ item.aveElapsedTime.minutes !== 1 ? "s" : "" }}              
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2" v-if="item.count > 0">
                        <div class="status-table-column-content">
                            <button type="button" class="btn btn-secondary process-view-btn show-modal-btn" @click="showViewModal(item.status)">
                                View Details
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="view-task-modal">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">View Task Status</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div v-if="selectedItem" >
                    <div class="row" v-for="taskItem in selectedItem.itemRecords" :key="taskItem.id">
                        <div class="col-lg-4">
                            <div class="status-table-column-header">Order ID</div>
                            <div class="status-table-column-content">{{ taskItem.id }}</div>
                        </div>
                        <div class="col-lg-2">
                            <div class="status-table-column-header">Product</div>
                            <div class="status-table-column-content">{{ taskItem.description }}</div>
                        </div>
                        <div class="col-lg-2">
                            <div class="status-table-column-header">Customer</div>
                            <div class="status-table-column-content">{{ taskItem.customerName }}</div>
                        </div>
                        <div class="col-lg-1">
                            <div class="status-table-column-header">Status</div>
                            <div class="status-table-column-content">{{ taskItem.status }}</div>
                        </div>
                        <div class="col-lg-3">
                            <div class="status-table-column-header">Time At Status</div>
                            <div class="status-table-column-content">
                                {{ taskItem.timeAtStatus.days }} day{{ taskItem.timeAtStatus.days !== 1 ? "s" : "" }}
                                {{ taskItem.timeAtStatus.hours }} hour{{ taskItem.timeAtStatus.hours !== 1 ? "s" : "" }}
                                {{ taskItem.timeAtStatus.minutes }} minute{{ taskItem.timeAtStatus.minutes !== 1 ? "s" : "" }} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary modal-btn" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'vue-chartjs';
ChartJS.register(ArcElement, Tooltip, Legend);
import VueCookies from 'vue-cookies';

var updateStatus;
var updateIntervalTimer;

export default {
    data() {
        return {
            taskStatus: null,
            loading: false,
            initialLoading: true,
            byStatusChartOptions: {
                responsive: true,
                maintainAspectRatio: true
            },
            lastUpdated: "",
            updateChanged: false,
            selectedItem: null,
        };
    },
    emits: ["login-expired"],
    methods: {
        async loadStatus() {
            this.loading = true;
            this.$store.dispatch('items/loadTaskStatus', VueCookies.get('wfdemo-fe-auth'))
            .then((taskStatus) => {
                this.taskStatus = taskStatus;
                this.loading = false;
                this.lastUpdated = new Date().toLocaleString();
                if(this.initialLoading) {
                    this.initialLoading = false;
                } else {
                    this.updateChanged = true;
                    setTimeout(() => {
                        this.updateChanged = false;
                    }, 3000);
                }
            })
            .catch((result) => {
                this.loading = false;
                if(result.response.status === 401) {
                    this.$emit('login-expired');
                }
            }); 
        },
        showViewModal(status) {
            this.taskStatus.forEach((item) => {
                if(item.status === status) {
                    this.selectedItem = item;
                    const theModal = new bootstrap.Modal(document.getElementById('view-task-modal'));
                    theModal.show();
                }
            });
        },
        isEven(index) {
            return index % 2 === 0;
        }
    },
    computed: {
        byStatusChartData() {
            var labels = [];
            var values = [];
            if(this.taskStatus !== null) {
                this.taskStatus.forEach(function(item) {
                    if(item.count > 0) {
                        labels.push(item.status);
                        values.push((item.aveElapsedTime.unix / 3600).toFixed(1));
                    }
                });
            }
            return  {
                labels: labels,
                datasets: [ 
                    { 
                        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                        data: values
                    } 
                ]
            }
        }
    },
    created() {
        updateStatus = this.loadStatus;
        this.loadStatus();
        if(process.env.VUE_APP_DASHBOARD_UPDATE > 0) {
            updateIntervalTimer = window.setInterval(
                function() {
                    updateStatus();
                },
                process.env.VUE_APP_DASHBOARD_UPDATE
            );
        }
    },
    beforeUnmount() {
        clearInterval(updateIntervalTimer);
    },
    components: {
        Pie
    }
}
</script>
