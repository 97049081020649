<template>
  <div class="row" >
    <div class="col-lg-8">
        <h2>Dave Feltz Software Developer LLC's Workflow Demo</h2>
    </div>
  </div>

  <div class="row" >
      <div class="col-lg-8">
          <h4>Introduction</h4>
          <p class='documentation-text'>
              Our demo on workflow automation showcases a straightforward and 
              foundational solution, highlighting the basic functionality for
              ease of understanding. The demo features a hypothetical company 
              called Widget Co. The company's primary business is manufacturing 
              tailored widgets for their clientele. Our demo's focus is on the 
              automation of the order management process.
          </p>
          <p class='documentation-text'>
              <i>
                  Note that in the interest of simplicity, this demonstration 
                  only showcases a fundamental range of features. However, as a 
                  custom software development company, we possess the capacity 
                  to tailor solutions to virtually any client needs. Therefore, 
                  what you see here is merely a fraction of the potential 
                  solutions we are equipped to provide.
              </i>
          </p>
      </div>
  </div>

  <div class="row" >
      <div class="col-lg-8">
          <h4>Demo basics</h4>
          <p class='documentation-text'>
              There are 5 user roles:
              <ol class='documentation-text'>
                  <li><b>Manager</b></li>
                  <li><b>Sales</b></li>
                  <li><b>Assembler</b></li>
                  <li><b>Inspector</b></li>
                  <li><b>Shipper</b></li>
              </ol>
          </p>
          <p class='documentation-text'>
              Here's an overview of the five-step order process:
              <ol class='documentation-text'>
                  <li>
                      <b>Entered</b> - 
                      This is the initial stage where the Sales team enters 
                      the order details but hasn't yet released it for assembly.
                  </li>
                  <li>
                      <b>Assembly</b> - 
                      In this stage, the Assembler takes over to construct the widget.
                  </li>
                  <li>
                      <b>Inspection</b> -
                      Once assembly is complete, the order undergoes a quality check.
                  </li>
                  <li>
                      <b>Shipping</b> -
                      This phase involves packaging the assembled order for shipment.
                  </li>
                  <li>
                      <b>Completed</b> - 
                      Once the order has been shipped, it moves to the final stage and is deemed complete.

                  </li>
              </ol>
          </p>
      </div>
  </div>

  <div class="row" >
      <div class="col-lg-8">
          <p class='documentation-text'>
              The credentials provided during signup are for a manager role, so 
              after logging in for the first time, it is recommended to create 
              logins for other user roles. This can be done by accessing 
              the "User Admin" option in the manager navigation menu. Once all 
              user roles are created, you can log in as any user. If desired, 
              you can simultaneously log in to multiple roles using multiple browsers 
              or devices.
          </p>
      </div>
  </div>

  <div class="row" >
      <div class="col-lg-8">
          <h4>Manager role dashboard</h4>
          <p class='documentation-text'>
              The home dashboard for the manager role encompasses three key screens:
              <ol class='documentation-text'>
                  <li>
                      <b>Process Status</b> - This screen provides an overview of all 
                      orders that are yet to be completed. It also offers the 
                      flexibility to move orders to different steps if required. 
                      Please note that initially there are no orders in progress,
                      but the sales role user can create some for testing purposes.
                  </li>
                  <li>
                      <b>Task Status</b> - This screen displays the number of orders at each 
                      process step, along with the average time spent by orders at 
                      each step. Additionally, the manager has the option to view a 
                      list of orders at a specific process step.
                  </li>
                  <li>
                      <b>User Admin</b> - This screen presents a comprehensive list of 
                      users, granting the manager the ability to create, edit, 
                      or deactivate user accounts as needed.
                  </li>
              </ol>
          </p>
      </div>
  </div>

  <div class="row" >
      <div class="col-lg-8">
          <h4>Sales dashboard</h4>
          <p class='documentation-text'>
              The sales role dashboard consists of one screen, which serves as a 
              centralized hub for managing orders. This screen includes the Task 
              Queue, where all entered orders that have yet to be started are displayed. 
              Here, users have the ability to edit these orders or classify them as
              "Ready For Assembly". Additionally, the sales dashboard provides a 
              "New Order" button for creating new orders.
          </p>
      </div>
  </div>

  <div class="row" >
      <div class="col-lg-8">
          <h4>The dashboard for assembler, inspector, and shipper roles</h4>
          <p class='documentation-text'>
              The assembler, inspector, and shipper roles have a single screen 
              dashboard called the Task Queue. This screen displays all the 
              orders currently in that specific step of the process. When the 
              current step is completed, there is a button available to 
              advance the order to the next step. 
          </p>
      </div>
  </div>

  <div class="row" >
      <div class="col-lg-8">
          <p class='documentation-text'>
              Currently the dashboard for all roles refreshes every 5 seconds 
              so changes made by a user role will reflect on the other applicable 
              dashboards within 5 seconds.
          </p>
      </div>
  </div>

  <div class="row" >
      <div class="col-lg-8">
          <p class='documentation-text'>
              Thank you for trying our demo. If you have any questions or 
              encounter problems, let us know at info@davefeltz.com.
          </p>
      </div>
  </div>
  <div class="row" >
      <div class="col-lg-6"><hr></div>
  </div>
  <div class="row" >
      <div class="col-lg-8">
          <p class='documentation-text'>
              Interested in a workflow automation solution for your 
              business? Take advantage of a complimentary consultation with 
              no strings attached and zero pressure.
          </p>
          <a href='https://www.davefeltz.com/free-consultation' class='btn btn-success' target='_blank'>FREE CONSULTATION</a>
      </div>
  </div>
  <br/>
</template>

<script>
import VueCookies from 'vue-cookies';

export default {
  name: "InstructionsView",
  props: {
    subPage: String
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  created() {
    if(!VueCookies.isKey('wfdemo-fe-auth')) {
      this.$router.push('/');
    }
  },
  components: {

  }
};

</script>
