<template>
    <div class="row status-table-first-row">
        <div class="col-lg-12">
            <h4 class="float-start">User Admin</h4>
            <div class="last-updated-container float-start"><span :class="updateChanged ? 'flashClass' : ''">Updated: {{ lastUpdated }}</span></div>
            <button type="button" class="btn btn-secondary new-user-btn show-modal-btn " @click="showUserModal()">New User</button>
        </div>
    </div>
    <div class="row" v-if="initialLoading && loading" >
        <div class="col-lg-12 text-center">
            <img src="@/assets/images/loader.gif" />
        </div>
    </div>
    <div v-if="!initialLoading || !loading" >
        <div v-for="(user, index) in activeUsers" :key="user.id" :class="this.isEven(index) ? 'row status-table-row status-table-even-row' : 'row status-table-row status-table-odd-row'">
            <div class="col-lg-2">
                <div class="status-table-column-header">Name</div>
                <div class="status-table-column-content">{{ user.name }}</div>
            </div>
            <div class="col-lg-2">
                <div class="status-table-column-header">Role</div>
                <div class="status-table-column-content">{{ user.role }}</div>
            </div>
            <div class="col-lg-2">
                <div class="status-table-column-header">User Name</div>
                <div class="status-table-column-content">{{ user.userName }}</div>
            </div>
            <div class="col-lg-2">
                <div class="status-table-column-header">Status</div>
                <div class="status-table-column-content">{{ user.isActive === "y" ? "Active" : "In Active" }}</div>
            </div>
            <div class="col-lg-2">
                <div class="status-table-column-header">Last Login</div>
                <div class="status-table-column-content">{{ new Date(user.lastLogin).toLocaleString() }}</div>
            </div>
            <div class="col-lg-2">
                <div class="status-table-column-content">
                    <button type="button" class="btn btn-secondary edit-user-btn show-modal-btn" @click="showUserModal(user.id)">
                        Edit
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="user_modal">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span v-if="selectedUser.id">Edit User</span>
                        <span v-if="!selectedUser.id">Add User</span>
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form id="user_form" @submit.prevent="saveUser">
                    <div class="modal-body">
                        <div><b>User's Full Name</b></div>
                        <div class="modal-item-content">
                            <input class="form-control" type="text" id="name" name="name" v-model="selectedUser.name" />
                            <ValidationMessage :model="v$.selectedUser.name" />
                        </div>
                        <div><b>User's Role</b></div>
                        <div class="modal-item-content">
                            <select id="role" name="role" class="modal-select form-select" v-model="selectedUser.role">
                                <option v-for="role in availableRoles" :key="role" :value="role">{{ role }}</option>
                            </select>
                        </div>
                        <div><b>Login Name</b></div>
                        <div class="modal-item-content">
                            <input class="form-control" type="text" id="username" name="username" v-model="selectedUser.userName" />
                            <ValidationMessage :model="v$.selectedUser.userName" />
                        </div>
                        <div><b>Is Active</b></div>
                        <div class="modal-item-content">
                            <select id="active" name="active" class="modal-select form-select" v-model="selectedUser.isActive">
                                <option v-for="active in availableActiveStates" :key="active" :value="active">{{ active }}</option>
                            </select>
                        </div>
                        <div>
                            <b>Password</b> 
                            <span class="small-red-text" v-if="selectedUser.id"> (Leave blank to keep the same)</span>
                        </div>
                        <div class="modal-item-content">
                            <input class="form-control" type="password" id="password" name="password" v-model="selectedUser.password"/>
                            <ValidationMessage :model="v$.selectedUser.password" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div v-if="showModalLoader" class="text-center">
                            <img class="modal-loader" src="@/assets/images/loader.gif" />
                        </div>
                        <button type="button" class="btn btn-secondary modal-btn" data-bs-dismiss="modal">Close</button>
                        <button class="btn btn-primary modal-btn" :disabled="showModalLoader">Save</button>
                    </div>
                </form>
                <div class="alert alert-danger save-error" v-if="saveUserError" v-html="saveUserError"></div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minLength, maxLength, alphaNum } from '@vuelidate/validators'
import ValidationMessage from '@/components/ValidationMessage.vue';

var updateActiveUsers;
var updateIntervalTimer;

export default {
    setup () {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            activeUsers: [],
            loading: false,
            initialLoading: true,
            lastUpdated: "",
            updateChanged: false,
            selectedUser: null,
            availableRoles: ['manager','sales', 'assembler','inspector','shipper'],
            saveUserError: "",
            userModal: null,
            showModalLoader: false,
            availableActiveStates: ['yes','no']
        };
    },
    validations () {
        return {
            selectedUser: {
                name: { 
                    required, 
                    maxLengthValue: maxLength(25),
                    $autoDirty: true 
                },
                userName: {
                    required, 
                    minLengthValue: minLength(3),
                    maxLengthValue: maxLength(15),
                    alphaNum: alphaNum,
                    $autoDirty: true 
                },
                password: { 
                    requiredIfSelectedUser: requiredIf(!this.selectedUser.id), 
                    $autoDirty: true 
                }
            }
        }
    },
    emits: ["login-expired", "show-success"],
    methods: {
        async loadActiveUsers() {
            this.loading = true;
            this.$store.dispatch('users/loadActiveUsers', VueCookies.get('wfdemo-fe-auth'))
                .then((activeUsers) => {
                    this.activeUsers = activeUsers;
                    this.loading = false;
                    this.lastUpdated = new Date().toLocaleString();
                    if(this.initialLoading) {
                        this.initialLoading = false;
                    } else {
                        this.updateChanged = true;
                        setTimeout(() => {
                            this.updateChanged = false;
                        }, 3000);
                    }
                })
                .catch((result) => {
                    this.loading = false;
                    if(result.response.status === 401) {
                        this.$emit('login-expired');
                    }                    
                });
        },
        showUserModal(userId = "") {
            this.saveUserError = "";
            if(userId === "") {
                this.emptySelectedUser();
            } else {
                this.activeUsers.forEach((user) => {
                    if(user.id === userId) {
                       this.selectedUser = {
                            id: user.id,
                            name: user.name,
                            role: user.role,
                            userName: user.userName,
                            isActive: user.isActive,
                            password: ""
            }
                        this.selectedUser.isActive = this.selectedUser.isActive === "y" ? "yes" : "no";
                    }
                });
            }
            this.userModal = new bootstrap.Modal(document.getElementById('user_modal'));
            this.userModal.show();
        },
       emptySelectedUser() {
            this.selectedUser = {
                id: "",
                name: "",
                role: "sales",
                userName: "",
                isActive: "yes",
                password: ""
            }
        },
        async saveUser() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                return;
            }

            this.saveUserError = '';
            this.showModalLoader = true;
            var saveUserAction;
            if(this.selectedUser.id.length > 0) {
                saveUserAction = 'users/updateUser';
            } else {
                saveUserAction = 'users/createUser';
            }
            this.$store.dispatch(saveUserAction, 
                {user: this.selectedUser, authToken: VueCookies.get('wfdemo-fe-auth')})
                    .then(() => {
                        this.showModalLoader = false;
                        this.userModal.hide();
                        this.loadActiveUsers();
                        this.$emit('show-success', 'User successfully updated');
                    })
                    .catch((result) => {
                        this.showModalLoader = false;
                        if(result.response.data.hasOwnProperty('messages')) {
                            if(Array.isArray(result.response.data.messages)) {
                                this.saveUserError =  result.response.data.messages.join('<br>');
                            } else {
                                if(result.response.data.messages.hasOwnProperty('error')) {
                                    this.saveUserError = result.response.data.messages.error;
                                } else {
                                    this.saveUserError = 'An error occurred';
                                }                                    
                            }
                        } else {
                            this.saveUserError = 'An error occurred';
                        }     
            });
        },
        isEven(index) {
            return index % 2 === 0;
        }
    },
    computed: {

    },
    created() {
        updateActiveUsers = this.loadActiveUsers;
        this.loadActiveUsers();
        if(process.env.VUE_APP_DASHBOARD_UPDATE > 0) {
            updateIntervalTimer = window.setInterval(
                function() {
                    updateActiveUsers();
                },
                process.env.VUE_APP_DASHBOARD_UPDATE
            );
        }
        this.emptySelectedUser();
    },
    beforeUnmount() {
        clearInterval(updateIntervalTimer);
    },
    components: {
        ValidationMessage,
    },
}
</script>
