import axios from 'axios';

export default {
  namespaced: true,
  state() {
    return {
      user: null,
      activeUsers: []
    };
  },
  getters: {

  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    signOutUser(state) {
      state.user = null;
    },
    setActiveUsers(state, activeUsers) {
      state.activeUsers = activeUsers;
    }
  },
  actions: {
    signIn({ commit }, userLogin) {
      return axios.post('/api/login', userLogin)
        .then((result) => {
          commit('setUser', result.data);
          return result.data;
        });
    },
    getUser({ commit, state }, authToken) {
      if(state.user === null) {
        return axios.get('/api/users/logged-in', {
          headers: {
            'WfDemo-Auth': authToken
          }
        }).then((result) => {
            commit('setUser', result.data);
            
          });
        }
    },
    signOut({ commit, state }, authToken) {
      commit('signOutUser');
      return axios.get('/api/users/logout', {
        headers: {
          'WfDemo-Auth': authToken
        }
       });
    },
    async loadActiveUsers({ commit }, authToken) {
      return axios.get('/api/users', {
          headers: {
            'WfDemo-Auth': authToken
          }
        }).then((result) => {
            commit('setActiveUsers', result.data);
            return result.data;
        });
    },
    createUser({ commit }, params) {
      return axios.post('/api/users', params.user, {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    },
    updateUser({ commit }, params) {
      return axios.put('/api/users/' + params.user.id, params.user, {
        headers: {
          'WfDemo-Auth': params.authToken
        }
       });
    }
  },
};
