<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse nav-pills" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 flex-column">
                    <li class="nav-item wf-nav-item">
                        <router-link class="nav-link" active-class="active" :to="{path:'/dashboard/task-queue'}"><i class="fa-solid fa-list-check nav-icon"></i> Task Queue</router-link>
                    </li>
                    <li class="nav-item wf-nav-item">
                        <a href="#" class="nav-link" aria-disabled="true" @click="signOut"><i class="fa-solid fa-door-closed nav-icon"></i> Logout</a>
                    </li>
                </ul>

            </div>
        </div>
    </nav>
</template>

<script>
export default {
    emits: ["sign-out"],
    data() {
        return {
            
        };
    },
    computed: {

    },
    created() {

    },
    methods: {
        signOut() {
            this.$emit('sign-out');
        },
    },
}
</script>

<style>
    .nav-link.active {
        color: black !important;
        background-color: #d3e3fd !important;
    }
</style>