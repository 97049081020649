<template>
    <div class="row status-table-first-row">
        <div class="col-lg-12">
            <h4 class="float-start">In process orders</h4>
            <div class="last-updated-container float-start"><span :class="updateChanged ? 'flashClass' : ''">Updated: {{ lastUpdated }}</span></div>
        </div>
    </div>
    <div class="row" v-if="initialLoading && loading" >
        <div class="col-lg-12 text-center">
            <img src="@/assets/images/loader.gif" />
        </div>
    </div>
    <div v-if="!initialLoading || !loading" >
        <div class="row">
            <div class="col-lg-3">
                <div class="text-center">Order count by customer</div>
                <Pie
                    id="by-customer-chart-id"
                    :options="byCustomerChartOptions"
                    :data="byCustomerChartData"
                />
            </div>
            <div class="col-lg-9">
                <div v-for="(item, index) in processStatus" :key="item.id" :class="this.isEven(index) ? 'row status-table-row status-table-even-row' : 'row status-table-row status-table-odd-row'">
                    <div class="col-lg-2">
                        <div class="status-table-column-header">Order ID</div>
                        <div class="status-table-column-content">{{ item.id }}</div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-header">Product</div>
                        <div class="status-table-column-content">{{ item.description }}</div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-header">Customer</div>
                        <div class="status-table-column-content">{{ item.customerName }}</div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-header">Status</div>
                        <div class="status-table-column-content">{{ item.status }}</div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-header">Created</div>
                        <div class="status-table-column-content">{{ item.created }}</div>
                        <div class="status-table-column-header">Updated</div>
                        <div class="status-table-column-content">{{ item.updated }}</div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-content">
                            <button type="button" class="btn btn-secondary process-view-btn show-modal-btn" @click="showViewEditModal(item.id)">
                                View/Edit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="view_item_modal">
        <div class="modal-dialog modal-md">
            <div v-if="selectedItem" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">View Order</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form id="view_edit_form" @submit.prevent="saveItemChanges">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <div><b>Order ID</b></div>
                                <div class="modal-item-content">{{ selectedItem.id }}</div>
                            </div>
                            <div class="col-lg-6">
                                <div><b>Product</b></div>
                                <div class="modal-item-content">{{ selectedItem.description }}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div><b>Customer</b></div>
                                <div class="modal-item-content">{{ selectedItem.customerName }}</div>
                            </div>
                            <div class="col-lg-6">
                                <div><b>Status</b></div>
                                <div class="modal-item-content">
                                    <select name="itemStatus" class="modal-select form-select changeable-field" v-model="updatedItem.status">
                                        <option v-for="status in availableItemStatuses" :key="status" :value="status">{{ status }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="selectedItem.notes.length > 0">
                            <div class="col-lg-12">
                                <div><b>Notes</b></div>
                                <div class="modal-item-content item-notes-container">
                                    <div v-for="note in selectedItem.notes" :key="note.id">{{ new Date(note.timestamp).toLocaleString() }} - {{ note.userRole }} : {{ note.message }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div><b>Add A Note</b></div>
                                <div class="modal-item-content">
                                    <div>
                                        <textarea class="form-control changeable-field" name="newNote" v-model="this.updatedItem.note"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div><b>Created</b></div>
                                <div class="modal-item-content">{{ new Date(selectedItem.created).toLocaleString() }}</div>
                            </div>
                            <div class="col-lg-6">
                                <div><b>Updated</b></div>
                                <div class="modal-item-content">{{ new Date(selectedItem.updated).toLocaleString() }}</div>
                            </div>
                        </div>                      
                    </div>
                    <div class="modal-footer">
                        <div v-if="showModalLoader" class="text-center">
                            <img class="modal-loader" src="@/assets/images/loader.gif" />
                        </div>
                        <button type="button" class="btn btn-secondary modal-btn" data-bs-dismiss="modal" :disabled="showModalLoader">Close</button>
                        <button class="btn btn-primary modal-btn" :disabled="showModalLoader">Save changes</button>
                        
                    </div>
                </form>
                <div class="alert alert-danger save-error" v-if="updatedItemError" v-html="updatedItemError"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'vue-chartjs';
ChartJS.register(ArcElement, Tooltip, Legend);
import VueCookies from 'vue-cookies';

var updateStatus;
var updateIntervalTimer;

export default {
    data() {
        return {
            processStatus: null,
            loading: false,
            initialLoading: true,
            byCustomerChartOptions: {
                responsive: true,
                maintainAspectRatio: true
            },
            lastUpdated: "",
            updateChanged: false,

            selectedItem: null,
            availableItemStatuses: ['entered','assembly','inspection','shipping','completed'],
            updatedItem: {
                id: "",
                note: "",
                status: "",
            },
            updatedItemError: "",
            editItemModal: null,
            showModalLoader: false,
        };
    },
    emits: ["login-expired", "show-success"],
    methods: {
        async loadProcessStatus() {
            this.loading = true;
            
            this.$store.dispatch('items/loadProcessStatus', VueCookies.get('wfdemo-fe-auth'))
                .then((processStatus) => {
                    this.processStatus = processStatus;
                    this.loading = false;
                    this.lastUpdated = new Date().toLocaleString();
                    if(this.initialLoading) {
                        this.initialLoading = false;
                    } else {
                        this.updateChanged = true;
                        setTimeout(() => {
                            this.updateChanged = false;
                        }, 3000);
                    }
                })
                .catch((result) => {
                    this.loading = false;
                    if(result.response.status === 401) {
                        this.$emit('login-expired');
                    }
                }); 
        },
        showViewEditModal(itemId) {
            this.updatedItemError = "";
            this.processStatus.forEach((item) => {
                if(item.id === itemId) {
                    this.selectedItem = item;
                    this.updatedItem.id = this.selectedItem.id;
                    this.updatedItem.status = this.selectedItem.status;
                    this.updatedItem.note = "";
                    this.editItemModal = new bootstrap.Modal(document.getElementById('view_item_modal'));
                    this.editItemModal.show();
                }
            });
        },
        saveItemChanges() {
            this.updatedItemError = '';
            if(this.updatedItem.status !== this.selectedItem.status || this.updatedItem.note.length > 0) {
                this.showModalLoader = true;
                this.$store.dispatch('items/updateItem', 
                    {updatedItem: this.updatedItem, authToken: VueCookies.get('wfdemo-fe-auth')})
                        .then(() => {
                            this.showModalLoader = false;
                            this.editItemModal.hide();
                            this.loadProcessStatus();
                            this.$emit('show-success', 'Item successfully updated');
                        })
                        .catch((result) => {
                            this.showModalLoader = false;
                            if(result.response.data.hasOwnProperty('messages')) {
                                if(Array.isArray(result.response.data.messages)) {
                                    this.updatedItemError =  result.response.data.messages.join('<br>');
                                } else {
                                    if(result.response.data.messages.hasOwnProperty('error')) {
                                        this.updatedItemError = result.response.data.messages.error;
                                    } else {
                                        this.updatedItemError = 'An error occurred';
                                    }                                    
                                }
                            } else {
                                this.updatedItemError = 'An error occurred';
                            }                           
                        });
            } else {
                this.editItemModal.hide();
            }
        },
        isEven(index) {
            return index % 2 === 0;
        }
    },
    computed: {
        byCustomerChartData() {
            var customers = [];
            var found = false;
            if(this.processStatus !== null) {
                this.processStatus.forEach(function(item) {
                    found = false;
                    customers.forEach(function(customer){
                        if(customer.name === item.customerName) {
                            customer.count++;
                            found = true;
                        }
                    });
                    if(!found) {
                        customers.push({name: item.customerName, count: 1});
                    }                
                });
            }
            var labels = [];
            var values = [];
            customers.forEach(function(customer) {
                labels.push(customer.name);
                values.push(customer.count);
            });
            return  {
                labels: labels,
                datasets: [ 
                    { 
                        backgroundColor: ['#DD1B16', '#41B883', '#E46651', '#00D8FF'],
                        data: values
                    } 
                ]
            }
        }
    },
    created() {
        updateStatus = this.loadProcessStatus;
        this.loadProcessStatus();
        if(process.env.VUE_APP_DASHBOARD_UPDATE > 0) {
            updateIntervalTimer = window.setInterval(
                function() {
                    updateStatus();
                },
                process.env.VUE_APP_DASHBOARD_UPDATE
            );
        }
    },
    beforeUnmount() {
        clearInterval(updateIntervalTimer);
    },
    components: {
        Pie
    }
}


</script>
