import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import DashboardView from "../views/DashboardView.vue";
import NotFoundView from "../views/PageNotFound.vue";
import NotAuthorized from "../views/NotAuthorized.vue";
import VueCookies from 'vue-cookies';
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: LoginView,
  },
  {
    path: '/dashboard/:subPage',
    name: 'dashboard',
    props: true,
    component: DashboardView,
    beforeEnter: async (to, from) => {
      
      try {
        if(!VueCookies.get('wfdemo-fe-auth')) {
          return "/";
        }
        await store.dispatch('users/getUser', VueCookies.get('wfdemo-fe-auth'));
      
        if(to.params.subPage === 'instructions') {
          return;
        }

        if((to.params.subPage === 'process-status' || 
            to.params.subPage === 'task-status' || 
            to.params.subPage === 'user-admin') &&  
            store.state.users.user.role === 'manager') {
          return;
        }

        if(to.params.subPage === 'task-queue' && store.state.users.user.role !== 'manager') {
          return;
        }

      } catch(e) { }
      
      return "/notauthorized";

    },
  },
  {
    path: '/notauthorized',
    component: NotAuthorized,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
