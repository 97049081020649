<template>
  <div class="row logged-out-home-title">
    <div class="col-lg-12 text-center">
      <h2>Workflow Automation Demo</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="login-block text-center">
        <form novalidate id="login_form" @submit.prevent="doLogin">
          <div class="login-block-title">
            Log In To Widget Co.'s Order Processing System
          </div>
          <div>
            <input
              class="form-control"
              type="text"
              v-model.trim="companyId"
              placeholder="Your company ID"
              required
            />
          </div>
          <div>
            <input
              class="form-control"
              type="text"
              v-model.trim="username"
              placeholder="Your user name"
              required
            />
          </div>
          <div>
            <input
              class="form-control"
              type="password"
              v-model.trim="password"
              placeholder="Your password"
              required
            />
          </div>
          <div>
            <button class="btn btn-primary" id="login_btn" :disabled="formSubmitted">Login</button>
          </div>
          <div id="error_div" v-if="signInError">Login failed</div>
        </form>
      </div>
    </div>
  </div>
  <div class="row logged-out-home-title">
    <div class="col-lg-12 text-center">
        <h4>Don't have a login?</h4>
        <a class="btn btn-success" href="https://www.davefeltz.com/solutions/workflow-automation#wf_demo_callout" target="_blank">Request Immediate Access</a>
    </div>
</div>
</template>

<script>
import VueCookies from 'vue-cookies';

export default {
  name: "LoginView",
  data() {
    return {
      companyId: VueCookies.get('wfdemo-fe-cid') ? VueCookies.get('wfdemo-fe-cid') : '',
      username: '',
      password: '',
      signInError: false,
      formSubmitted: false,
    };
  },
  methods: {
    doLogin() {
      this.signInError = false;
      this.formSubmitted = true;
      const userLogin = {
        companyId: this.companyId,
        userName: this.username,
        password: this.password,
      };
      this.$store.dispatch('users/signIn', userLogin)
        .then((user) => { 
          this.formSubmitted = false;
          VueCookies.set('wfdemo-fe-cid', this.companyId, 1209600);
          VueCookies.set('wfdemo-fe-auth',this.$store.state.users.user.authToken, 86400);   
          if(user.role === 'manager') {   
            this.$router.push('dashboard/process-status');
          } else {
            this.$router.push('dashboard/task-queue');
          }
        })
        .catch(() => {
          this.formSubmitted = false;
          this.signInError = true; 
        });
    },
  },
  created() {
    if(VueCookies.get('wfdemo-fe-auth')) {
      this.$store.dispatch('users/getUser', VueCookies.get('wfdemo-fe-auth'))
        .then(() => { 
          if(this.$store.state.users.user.role === 'manager') {   
            this.$router.push('dashboard/process-status');
          } else {
            this.$router.push('dashboard/task-queue');
          }
        })
        .catch(() => {
          
        });
    }
  }
};
</script> 
