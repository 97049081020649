<template>
  <div class="row">
    <div class="col-lg-2 left-nav-column">
      <div id="logged_in_name">{{ user.name }} - {{ user.role }}</div>
        <ManagerNav v-if="user.role === 'manager'" :subPage="subPage" @sign-out="signOut"/>
        <WorkerNav v-if="user.role !== 'manager'" :subPage="subPage" @sign-out="signOut"/>
      <div class="dashboard-company-name">{{ user.companyName }}</div>
      <router-link class="btn btn-secondary instructions-btn" active-class="active" :to="{path:'/dashboard/instructions'}">Instructions</router-link>
    </div>
    <div class="col-lg-10">
      <ProcessStatus v-if="user.role === 'manager' && subPage === 'process-status'" @login-expired="loginExpired" @show-success="showSuccessMessage"/>
      <TaskStatus v-if="user.role === 'manager' && subPage === 'task-status'"  @login-expired="loginExpired"/>
      <UserAdmin v-if="user.role === 'manager' && subPage === 'user-admin'" @login-expired="loginExpired" @show-success="showSuccessMessage"/>
      <TaskQueue :user="user" v-if="user.role !== 'manager' && user.role !== 'sales' && subPage === 'task-queue'" @login-expired="loginExpired" @show-success="showSuccessMessage"/>
      <SalesTaskStatus v-if="user.role === 'sales' && subPage === 'task-queue'" @login-expired="loginExpired" @show-success="showSuccessMessage"/>
      <InstructionsView v-if="subPage === 'instructions'" />
      <NotFound v-if="!subPageIsValid()" /> 
      <div class="alert alert-success success-alert col-lg-3" v-if="showSuccess">
        <i class="fa-solid fa-check"></i> {{ successMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import ProcessStatus from '@/components/ProcessStatus';
import TaskStatus from '@/components/TaskStatus';
import UserAdmin from '@/components/UserAdmin';
import ManagerNav from '@/components/ManagerNav';
import WorkerNav from '@/components/WorkerNav';
import TaskQueue from '@/components/TaskQueue';
import SalesTaskStatus from '@/components/SalesTaskStatus';
import InstructionsView from "@/views/InstructionsView.vue";
import NotFound from '@/views/PageNotFound';
import VueCookies from 'vue-cookies';

export default {
  name: "DashboardView",
  props: {
    subPage: String
  },
  data() {
    return {
      user: {
        name: "",
        role: "",
        companyName: ""
      },
      showSuccess: false,
      successMessage: '',
    };
  },
  methods: {
    signOut() {
      this.$store.dispatch('users/signOut', VueCookies.get('wfdemo-fe-auth'));
      VueCookies.remove('wfdemo-fe-auth');
      this.$router.push('/');
    },
    loginExpired() {
      VueCookies.remove('wfdemo-fe-auth');
      this.$router.push('/');
    },
    subPageIsValid() {
      return this.subPage === "process-status" || 
        this.subPage === "task-status" || 
        this.subPage === "user-admin" || 
        this.subPage === "task-queue" ||
        this.subPage === "instructions";
    },
    showSuccessMessage(message) {
      this.successMessage = message;
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 3000);
    },
  },
  async created() {
    if(VueCookies.get('wfdemo-fe-auth')) {
      await this.$store.dispatch('users/getUser', VueCookies.get('wfdemo-fe-auth'));
      this.user = this.$store.state.users.user;
    } else {
      this.$router.push('/');
    }

    
  },
  components: {
    ProcessStatus: ProcessStatus,
    TaskStatus: TaskStatus,
    UserAdmin: UserAdmin,
    TaskQueue: TaskQueue,
    SalesTaskStatus: SalesTaskStatus,
    InstructionsView: InstructionsView,
    ManagerNav,
    WorkerNav,
    NotFound
  }
};

</script>
<style scoped>
  .success-alert {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    text-align: center;
    z-index: 9999;
  }
</style>
