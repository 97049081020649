<template>
    <div class="row status-table-first-row">
        <div class="col-lg-12">
            <h4 class="float-start">
                <span>Orders Entered But Not Started</span>
            </h4>
            <div class="last-updated-container float-start"><span :class="updateChanged ? 'flashClass' : ''">Updated: {{ lastUpdated }}</span></div>
        </div>
        <button style="display: inline; max-width: 150px;" type="button" class="btn btn-secondary new-item-btn show-modal-btn" @click="showItemModal()">New Order</button>
    </div>
    <div class="row" v-if="initialLoading && loading" >
        <div class="col-lg-12 text-center">
            <img src="@/assets/images/loader.gif" />
        </div>
    </div>
    <div v-if="!initialLoading || !loading" >
        <div class="row">
            <div class="col-lg-12">
                <div v-for="(item, index) in taskQueue" :key="item.id" :class="this.isEven(index) ? 'row status-table-row status-table-even-row' : 'row status-table-row status-table-odd-row'">
                    <div class="col-lg-3">
                        <div class="status-table-column-header">Order ID</div>
                        <div class="status-table-column-content">{{ item.id }}</div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-header">Product</div>
                        <div class="status-table-column-content">{{ item.description }}</div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-header">Customer</div>
                        <div class="status-table-column-content">{{ item.customer.name }}</div>
                    </div>
                    <div class="col-lg-3">
                        <div class="status-table-column-header">Time In Queue</div>
                        <div class="status-table-column-content">
                            {{ item.elapsedTime.days }} day{{ item.elapsedTime.days != 1 ? 's' : '' }},
                            {{ item.elapsedTime.hours }} hour{{ item.elapsedTime.hours != 1 ? 's' : '' }},
                            {{ item. elapsedTime.minutes }} minute{{ item. elapsedTime.minutes != 1 ? 's' : '' }}
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="status-table-column-content"><button type="button" class="btn btn-secondary edit-item-btn show-modal-btn"  @click="showItemModal(item.id)">View/Edit</button></div>
                        <div class="status-table-column-content" ><button type="button" class="btn btn-success start-assembly-btn show-modal-btn" @click="showToAssemblyModal(item.id)">Ready For Assembly</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="item-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span v-if="selectedItem.id">Edit Order</span>
                        <span v-if="!selectedItem.id">New Order</span>
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form id="item_form" @submit.prevent="saveItem">
                    <div class="modal-body">
                        <div><b>Customer</b></div>
                        <div class="modal-item-content">
                            <select id="customerId" name="customerId" class="modal-select form-select" v-model="selectedItem.customer.id">
                                <option value="">-- select customer --</option>
                                <option v-for="customer in availableCustomers" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                            </select>
                            <ValidationMessage :model="v$.selectedItem.customer.id" />
                        </div>
                        <div><b>Product Description</b></div>
                        <div class="modal-item-content">
                            <input class="form-control" type="text" id="productDescription" name="productDescription" v-model="selectedItem.description" />
                            <ValidationMessage :model="v$.selectedItem.description" />
                        </div>
                        <div><b>Notes</b></div>
                        <div class="modal-item-content item-notes-container">
                            <div v-for="note in selectedItem.notes" :key="note.id">{{ new Date(note.timestamp).toLocaleString() }} - {{ note.userRole }} : {{ note.message }}</div>
                        </div>
                        <div><b>Note (optional)</b></div>
                        <div class="modal-item-content">
                            <div><textarea class="form-control changeable-field" id="note" name="note" v-model="selectedItem.newNote"></textarea></div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div v-if="showModalLoader" class="text-center">
                            <img class="modal-loader" src="@/assets/images/loader.gif" />
                        </div>
                        <button type="button" class="btn btn-secondary modal-btn" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary modal-btn" :disabled="showModalLoader">Save changes</button>
                    </div>
                </form>
                <div class="alert alert-danger save-error" v-if="saveItemError" v-html="saveItemError"></div>
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" id="to-assembly-modal">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Confirm Ready For Assembly
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure you want to send the order to the assembler?
                </div>
                <div class="modal-footer">
                    <div v-if="showModalLoader" class="text-center">
                        <img class="modal-loader" src="@/assets/images/loader.gif" />
                    </div>
                    <button type="button" class="btn btn-secondary modal-btn confirm-modal-btn" data-bs-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary modal-btn confirm-modal-btn" @click="sendToAssembler()">Yes</button>
                </div>
                <div class="alert alert-danger save-error" v-if="toAssemblyError" v-html="toAssemblyError"></div>
            </div>
        </div>
    </div>

</template>

<script>
import VueCookies from 'vue-cookies';
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minLength, maxLength, alphaNum } from '@vuelidate/validators'
import ValidationMessage from '@/components/ValidationMessage.vue';

var updateQueue;
var updateIntervalTimer;

export default {
    setup () {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            taskQueue: [],
            loading: false,
            initialLoading: true,
            lastUpdated: "",
            updateChanged: false,
            selectedItem: null,
            saveItemError: "",
            showModalLoader: false,
            availableCustomers: [
                {
                    id: "36bc17c2-139a-45d8-8d1b-f448128fba08",
                    name: "Center Manufacturing"
                },
                {
                    id: "81ef1154-105d-4ce6-b846-21b5a6b767ef",
                    name: "Herby Construction"
                },
            ],
            itemModal: null,

            toAssemblyError: "",
            toAssemblyModal: null,
            toAssemblyItemId: null,

        };
    },
    validations () {
        return {
            selectedItem: {
                description: { 
                    required, 
                    maxLengthValue: maxLength(25),
                    $autoDirty: true 
                },
                customer: {
                    id: {
                        required, 
                        minLengthValue: minLength(36),
                        maxLengthValue: maxLength(36),
                        $autoDirty: true 
                    }
                }
            }
        }
    },
    emits: ["login-expired", "show-success"],
    methods: {
        async loadQueue() {
            this.loading = true;
            this.$store.dispatch('items/loadTaskQueue', VueCookies.get('wfdemo-fe-auth'))
            .then((taskQueue) => {
                this.taskQueue = taskQueue;
                this.loading = false;
                this.lastUpdated = new Date().toLocaleString();
                if(this.initialLoading) {
                    this.initialLoading = false;
                } else {
                    this.updateChanged = true;
                    setTimeout(() => {
                        this.updateChanged = false;
                    }, 3000);
                }
            })
            .catch((result) => {
                this.loading = false;
                if(result.response.status === 401) {
                    this.$emit('login-expired');
                }
            }); 
        },
        showItemModal(itemId = "") {
            this.saveItemError = "";
            if(itemId === "") {
                this.emptySelectedItem();
            } else {
                this.taskQueue.forEach((item) => {
                    if(item.id === itemId) {
                       this.selectedItem = {
                            id: item.id,
                            description: item.description,
                            customer: {
                                id: item.customer.id,
                                name: item.customer.name,
                            },
                            notes: item.notes,
                            newNote: ""
                        }
                    }
                });
            }
            this.itemModal = new bootstrap.Modal(document.getElementById('item-modal'));
            this.itemModal.show();
        },
       emptySelectedItem() {
            this.selectedItem = {
                id: "",
                description: "",
                customer: {
                    id: "",
                    name: "",
                },
                notes: [],
                newNote: ""
            }
        },
        async saveItem() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                return;
            }

            this.saveItemError = '';
            this.showModalLoader = true;

            const itemToSave = {
                id: this.selectedItem.id,
                description: this.selectedItem.description,
                customerId: this.selectedItem.customer.id,
                newNote: this.selectedItem.newNote
            };

            var saveItemAction;
            if(this.selectedItem.id.length > 0) {
                saveItemAction = 'items/salesUpdateItem';
            } else {
                saveItemAction = 'items/salesCreateItem';
            }
            this.$store.dispatch(saveItemAction, 
                {item: itemToSave, authToken: VueCookies.get('wfdemo-fe-auth')})
                    .then(() => {
                        this.showModalLoader = false;
                        this.itemModal.hide();
                        this.loadQueue();
                        this.$emit('show-success', 'Item successfully updated');
                    })
                    .catch((result) => {
                        this.showModalLoader = false;
                        if(result.response.data.hasOwnProperty("messages")) {
                            this.saveItemError = result.response.data.messages.join('<br>');
                        } else if(result.response.data.hasOwnProperty("message")) {
                            this.saveItemError = result.response.data.message;
                        } else {
                            this.saveItemError = 'An error occurred';
                        }                        
            });
        },
        showToAssemblyModal(itemId) {
            this.toAssemblyItemId = itemId;
            this.toAssemblyError = "";
            this.toAssemblyModal = new bootstrap.Modal(document.getElementById('to-assembly-modal'));
            this.toAssemblyModal.show();
        },
        async sendToAssembler() {
            this.toAssemblyError = "";
            this.showModalLoader = true;

            this.$store.dispatch("items/sendToAssembly", 
                {itemId: this.toAssemblyItemId, authToken: VueCookies.get('wfdemo-fe-auth')})
                    .then(() => {
                        this.showModalLoader = false;
                        this.toAssemblyModal.hide();
                        this.loadQueue();
                        this.$emit('show-success', 'Item sent to assembly');
                    })
                    .catch((result) => {
                        this.showModalLoader = false;
                        if(result.response.data.hasOwnProperty('messages')) {
                            if(Array.isArray(result.response.data.messages)) {
                                this.toAssemblyError =  result.response.data.messages.join('<br>');
                            } else {
                                if(result.response.data.messages.hasOwnProperty('error')) {
                                    this.toAssemblyError = result.response.data.messages.error;
                                } else {
                                    this.toAssemblyError = 'An error occurred';
                                }                                    
                            }
                        } else {
                            this.toAssemblyError = 'An error occurred';
                        }     
            });
        },
        isEven(index) {
            return index % 2 === 0;
        }
    },
    computed: {
        
    },
    created() {
        updateQueue = this.loadQueue;
        this.loadQueue();
        if(process.env.VUE_APP_DASHBOARD_UPDATE > 0) {
            updateIntervalTimer = window.setInterval(
                function() {
                    updateQueue();
                },
                process.env.VUE_APP_DASHBOARD_UPDATE
            );
        }
        this.emptySelectedItem();
    },
    beforeUnmount() {
        clearInterval(updateIntervalTimer);
    },
    components: {
        ValidationMessage,
    }
}
</script>
