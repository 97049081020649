import { createStore } from "vuex";
import usersModule from "./modules/users";
import itemsModule from "./modules/items";

export default createStore({
  modules: {
    users: usersModule,
    items: itemsModule
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
