<template>
    <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
            <h1 class="display-1 fw-bold">403</h1>
            <p class="fs-3"> <span class="text-danger">Opps!</span> Not authorized.</p>
            <p class="lead">
                You are not authorized to access that page.
            </p>
            <p>
                <button class="btn btn-primary" @click="$router.go(-1)">Go back</button>
            </p>
        </div>
    </div>
</template>
